@import "./variables";

.layout-userbar {
    position: fixed;
    top: $topbar-height;
    left: 0;
    z-index: 997;
    height: $userbar-height;
    width: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    
    background-color: #1E396C;
    padding-bottom: $userbar-border-height;
    
    &:after {
        content:"";
        background: -webkit-linear-gradient(left, #2F75B6 0%, #B4C7E7 100%);
        display: block;
        height: $userbar-border-height;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .layout-userbar-container {
        width: 100%;
        height: 100%;
        display: flex;
        // justify-content: center;
        // align-items: center;
        color: #ffffff;
        font-weight: bold;
        // font-size: 20px;
        padding: 0 35px;

        .layout-userbar-details {
            display: flex;
            align-items: center;
            z-index: 1;

            .rate {
                color: #f2b837;
                font-size: 25px;
                font-weight: bold;
                margin: 0 .25rem;
            }

            .helper-text {
                background-color: #f2b837;
                color: #212121;
                padding: 0 0.2rem;
                margin-right: 0.3rem;
            }
        }

        img.layouts-userbar-mockup-image {
            height: $userbar-height;
        }

        .layout-userbar-button {
            border: 1px solid #ffffff;
            color: #ffffff;
            font-size: 15px;
            padding: .3em .3em .5em 1em;
            font-weight: bold;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            transition: background-color 0.15s;
            margin-left: 2em;
            position: relative;
            z-index: 1;

            &:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }
        }
    }

    .layout-userbar-close {
        cursor: pointer;
        color: #fff;
        position: absolute;
        z-index: 2;
        right: 28px;
        background-color: rgba(0,0,0,.3);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
    }
}

.layout-wrapper.layout-userbar-active {
    .layout-topbar {
        // top: 70px;
        top: 0px;
    }

    .layout-sidebar {
        top: 110px;
        height: calc(100% - #{$topbar-total-height});
    }

    .layout-content {
        padding-top: $topbar-total-height;
    }
}