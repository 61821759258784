.layout-footer {
    background-color: #003049;
    color: #CCCCCC;
    h4 {
        color: #FFFFFF !important;
    }

    a {
        color: #CCCCCC;

        &:hover {
            color: #5BAF1F !important;
        }
    }



    .layout-footer-social {
        i {
            font-size: 36px;
        }
    }

    &.second {
        background-color: #003049;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        margin: 6vw 1.5rem 0vw 1.5rem;
    }
}

.sub {
    background: #5BAF1F;
    position: relative;
    width: 80%;
    min-height: 10vh;
    color: #FFFFFF;
    margin: auto;
    padding: 0px 15px;
    top: -5vw;
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;
}

.sub:before {
    border-bottom: 2vw solid #5BAF1F;
    border-right: 3vw solid transparent;
    border-top-left-radius: 2vw;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -2vw;
    width: 100%;
}

.sub-input {
    width: 60%;
    font-size: 1.4vw;
}

.p-button.sub-btn {
    // width: 4rem;
    font-size: 1.4vw;
}

.text-sub {
    margin: 2vw 0;
}

.header-text {
    color: #FFFFFF;
}