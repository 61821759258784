.statute-table {
  thead {
    tr {
      th {
        color: var(--table-header-color) !important;
      }
    }
  }
}

/*** removing blue outline **/

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

/*** fixed rounded button **/

.p-button.p-button-icon-only.p-button-rounded {
  height: 2.5rem;
}

/*** Typography **/

h1,
h2,
h3,
h4,
h5 {
  color: var(--header-color) !important;
}

.text-primary {
  color: var(--text-color) !important;
}

.align-self-center {
  align-self: center;
}

.input-mark-required {
  color: var(--bs-danger);
  // // margin-left: 5px;
  // left: 5px;
  // // width: 0px;
  // position: relative;

  // position: absolute;
  // line-height: 2rem;
  // padding-left: 5px;

  &:after {
    content: "*";
  }
}

hr {
  color: var(--header-color) !important;
  height: 2px !important;
}

.text-strong {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

/*** Theme override **/

::placeholder {
  color: var(--placeholder-color);
}

.placeholder {
  color: var(--placeholder-color);
}

.p-inputtext,
.p-multiselect {
  border: 2px solid #ced4da;
  border-radius: 6px;
  font-family: inherit;
}

.p-dropdown {
  border: 2px solid #ced4da;
  border-radius: 6px;
  text-align: left;
}

.p-button {
  border-radius: 6px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: none;
}

.pointer {
  cursor: pointer;
}

.p-float-label>label,
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #CCCCCC;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
  background-color: #dee2e6;
}
.card-blue .p-card {
  margin-bottom: 0.5em;
  border-radius: $border-radius;
  box-shadow: none;
  background-color: #f1f1f4;

  .p-card-header {
    background-color: #3a7cba;
    color: #fff;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 0.75em 1em;
    font-size: 1.1rem;
    font-weight: 600;
  }
}

p-card {
  .default-p-card {
    padding: 2rem;
  }

  &.p-banner-card.card-blue {
    .p-card {
      margin-bottom: 0;
    }
  }
}

.panel-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5em;
}

/*** Form **/
// .p-fluid {
//   input.p-inputtext,
//   textarea.p-inputtextarea,
//   p-dropdown,
//   p-multiselect,
//   .p-multiselect {
//     width: calc(100% - 8px);
//   }

//   p-dropdown,
//   p-multiselect {
//     display: inline-flex;

//     .p-dropdown,
//     .p-multiselect {
//       width: 100%;
//     }
//   }

//   .p-multiselect-filter {
//     width: 100% !important;
//   }
// }

/*** Theme override **/

::placeholder {
  color: var(--placeholder-color);
}

.placeholder {
  color: var(--placeholder-color);
}

.p-inputgroup .p-inputtext {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.p-inputgroup > .p-button {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background-color: #5BAF1F !important;
  border-color: #5BAF1F !important;
}

.p-inputtext,
.p-multiselect {
  border: 2px solid #ced4da;
  border-radius: 6px;
  font-family: inherit;
  
}

.p-dropdown {
  border: 2px solid #ced4da;
  border-radius: 6px;
}

.p-button {
  border-radius: 6px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: none;
}

.p-action {
  span{
      font-size: 2.5rem;
  }
  width: 3.5rem;
  height: 3.5rem;
  z-index: 1;
}

.p-component {
  font-family: inherit;
}

.p-slidemenu .p-slidemenu-content {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.single {
  .p-slidemenu .p-slidemenu-content {
    // min-height: 300px;
    min-height: 120px;
  }
}

.multiple {
  .p-slidemenu .p-slidemenu-content {
    // min-height: 330px;
    min-height: 200px;
    overflow: hidden;
  }
}

.p-slidemenu.p-slidemenu-overlay {
  min-width: 200px;
  top: 3rem !important;
  right: 3rem !important;
  left: auto !important;
}

.noti {
  .p-slidemenu.p-slidemenu-overlay {
    min-width: 300px;
    top: 5.2rem!important;
    right: 1rem !important;
    left: auto !important;
  }
  .p-slidemenu .p-menuitem-link {
    border-bottom: 1px solid #495057;
  }
}
.login {
  width: 45% !important;

  .p-card {
    width: 100%;
  }
}

.card-login {
  width: 480px;
  background-color: #fafafa !important;

  .p-card {
    width: 100%;
  }
}

.p-inputtext.ng-touched.ng-invalid,
p-dropdown.ng-touched.ng-invalid .p-dropdown,
p-inputmask.ng-touched.ng-invalid .p-inputtext,
p-dropdown.error.ng-invalid .p-dropdown,
p-radiobutton.ng-touched.ng-invalid .p-radiobutton-box,
.p-input-icon-right.ng-touched.ng-invalid > .p-inputtext,
p-multiselect.ng-touched.ng-invalid .p-multiselect {
  border-color: #f44336;
}

.p-disabled,
.p-input:disabled,
.p-inputtext:disabled {
  opacity: 0.6;
  background-color: #dee2e6;
}

.custom-p-disabled {
  cursor: auto !important;
  opacity: 0.65;
}

.focus {
  outline: 0 none;
  outline-offset: 0;
  // box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3 !important;
}

/*** Dialog ***/
.p-dialog {
  border-radius: 10px;

  .p-dialog-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .dialog-header-content {
      width: 100%;
      text-align: center;
      color: var(--header-color);
      font-weight: bold;
      font-size: 1.1rem;
    }

    .dialog-header-content-worning {
      width: 100%;
      text-align: center;
      color: var(--bs-danger);
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .p-dialog-content {
    .confirm-label {
      color: var(--header-color);
      font-weight: bold;
    }

    .warning-label {
      color: var(--primary-color);
      font-weight: bold;
    }

    .confirm-value {
      margin-left: 5px;
    }
  }

  .p-dialog-footer {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

p-dialog.custom-fullscreen-dialog {
  .p-dialog {
    height: 100% !important;
    left: 0 !important;
    max-height: 100%;
    top: 0 !important;
    transform: none;
    transition: none;
    width: 100vw !important;

    // border-radius: 0;
    // overflow-y: auto;
    // background-color: #fff;

    .p-dialog-header {
      display: flex;
      // justify-content: flex-end;
      padding-right: 2.75rem;

      border-top-right-radius: 0;
      border-top-left-radius: 0;

      // padding-top: 0.5rem;
      // padding-bottom: 0.5rem;
    }

    // .p-dialog-content {
    //   height: 100%;
    //   overflow-y: unset;
    // }

    .p-dialog-footer {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

p-dialog {
  .custom-fullscreen-dialog {
    .banner-preview-dialog {
      .p-dialog {
        max-width: 100vw !important;

        .p-dialog-header {
          justify-content: space-between;
        }
      }
    }
  }

  .p-dialog-mask {
    .p-dialog {
      .p-dialog-footer {
        margin-top: -1px;
      }
    }
  }
}

.p-dialog-mask {
  // fixed dropdown display under its parent dialog on mobile screen size
  z-index: 1000;
}

/*** Some popup ***/
.popup-edit-mode {
  &.some {
    .p-dialog {
      width: 90% !important;
    }
  }

  &.default {
    .p-dialog {
      width: 500px !important;
    }
  }
}

/*** Drag and drop ***/
.cdk-drag {
  z-index: 1005 !important;
}

/*** Confirm Popup **/
.p-confirm-dialog {
  .p-dialog-footer {
    display: inline-flex;
    justify-content: center;

    .p-button.p-confirm-dialog-accept {
      order: 1;
    }

    .p-button.p-confirm-dialog-reject {
      order: 2;
    }
  }

  .p-confirm-dialog-message {
    margin-left: 1rem;
    max-width: 400px;
  }
}

/*** panel-search-filter **/
.panel-search-filter {
  .p-field-checkbox {
    display: flex;

    .p-checkbox {
      margin-right: 0.5rem !important;
    }
  }

  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        border: none;
      }

      &:not(:last-child) {
        .p-accordion-header-link {
          border-bottom: 1px solid #dee2e6;
        }
      }
    }

    .p-accordion-content {
      .p-field-checkbox {
        &:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .p-accordion-as-header {
    padding: 1rem;
    border: none;
    color: #495057;
    background: #f8f9fa;

    &:not(:last-child) {
      border-bottom: 1px solid #dee2e6;
    }
  }
}

/**** divider ***/
.without-first-divider {
  p-divider:first-child {
    display: none;
  }
}

.without-last-divider {
  p-divider:last-child {
    display: none;
  }
}

.mat-form-field.input-tags {
  letter-spacing: initial;
  border: $border-style;
  border-radius: $border-radius;
  padding: 0.5em;
  width: 100%;

  &:hover {
    border-color: #2196f3 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
    padding: 0;
  }

  .icon-remove {
    margin-left: 4px;
  }

  .mat-chip {
    border-radius: $border-radius;
    padding-right: 8px;
    padding-left: 8px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-chip-input {
    line-height: 1.5rem;
    margin: 4px 6px;
  }

  .mat-chip {
    font-size: 1rem;
  }

  .mat-standard-chip {
    height: 100%;
  }
}

app-public-doc-home,
app-public-search-home {
  .dynamic-left-menu {
    .layout-left-menu {
      width: 350px !important;
      min-width: 350px !important;
    }
  }
}

.app-public-panel-section {
  .checkbox-paragraph-space {
    width: calc(20px + 0.5rem);
  }

  .section-link {
    color: var(--blue-500);
  }

  .section-unlink {
    color: #495057;
  }

  .doc-section-btn {
    min-width: 36px;
    // height: 22px;
    background-color: var(--blue-800);
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  .doc-section-btn:hover {
    background-color: #1e396c;
  }

  .highlight-section {
    background-color: #ddd;
  }

  p-accordion {
    p-accordiontab {
      .p-accordion-tab {
        .p-toggleable-content {
          .p-accordion-content {
            padding: 0;
          }
        }
      }
    }
  }

  a {
    &.active {
      // background-color: #f1f1f4;
      // background-color: #e9ecef;
      background-color: none;
    }

    &:focus {
      outline: none;
      border: none;
      border-color: transparent;
    }
  }

  .active-row {
    background-color: #e9ecef;
  }

  hr {
    height: 0.5px !important;
  }

  .link-text {
    cursor: pointer;
    color: var(--blue-500);
  }

  // .custom-parent-section {
  //   width: 20px;
  //   height: 20px;
  //   border-radius: 3px;
  //   border: 2px solid #ced4da;
  //   cursor: pointer;

  //   &:hover {
  //     border-color: var(--blue-500);
  //   }

  //   .custom-parent-section-icon {
  //     background: var(--blue-500);
  //     width: 10px;
  //     height: 10px;
  //     border-radius: 1.5px;
  //   }
  // }
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 2px solid #ced4da;
  cursor: pointer;

  &:hover {
    border-color: var(--blue-500);
  }

  .custom-checkbox-icon {
    background: var(--blue-500);
    width: 17px;
    height: 4px;
    border-radius: 1.5px;
  }
}

.app-public-panel-body {
  p-accordion {
    p-accordiontab {
      .p-accordion-header {
        width: 164px;

        &:not(.p-disabled).p-highlight {
          .p-accordion-header-link {
            border: none;
            background: none;
          }
        }

        .p-accordion-header-link {
          border: none;
          background: none;
        }
      }

      .p-toggleable-content {
        .p-accordion-content {
          border-top: 1px solid #dee2e6;

          // min-height: 186px;
          min-height: 200px;
          position: relative;
          overflow-x: auto;
        }
      }
    }

    .timeline-button:hover {
      background: #d3eafd !important;
    }
  }

  hr {
    height: 0.5px !important;
  }
}

p-accordion.timeline-panel {
  p-accordiontab {
    .p-accordion-header {
      width: 164px;

      &:not(.p-disabled).p-highlight {
        .p-accordion-header-link {
          border: none;
          background: none;
          color: #495057;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .p-accordion-header-link {
        border: none;
        background: none;
        color: #495057;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .p-toggleable-content {
      .p-accordion-content {
        border-top: 1px solid #dee2e6;

        // min-height: 186px;
        min-height: 200px;
        position: relative;
        overflow-x: auto;
      }
    }
  }

  .timeline-button:hover {
    background: #d3eafd !important;
  }
}

.app-public-browse,
.app-public-commentary {

  app-public-browse-panel,
  app-public-commentary-panel {
    .p-tabmenu {
      .p-tabmenu-nav {
        // border: none;

        .p-tabmenuitem {
          &.p-highlight {
            .p-menuitem-link {
              border-bottom: 2px solid var(--blue-500);
              // margin-bottom: -2px;
            }
          }

          .p-menuitem-link {
            border: none;
            margin: 0;
          }
        }
      }
    }
  }

  app-dynamic-left-menu {
    .dynamic-left-menu {
      .layout-left-menu.sticky {
        min-width: 420px;
      }

      .layout-content {
        min-width: calc(100% - 425px);
      }
    }
  }

  app-public-browse-left,
  app-public-commentary-left {
    // p-accordion {
    //   width: 100%;

    //   .p-accordion-header {
    //     .p-accordion-header-link {
    //       padding: 0.5rem;
    //     }
    //   }
    // }

    .browse-menu-row {
      &:hover {
        background-color: #e9ecef;
      }
    }

    i {
      .pi {
        font-size: 1.2rem;
      }
    }

    .angle-down {
      transform: rotate(90deg);
    }

    .filter-header {
      cursor: pointer;

      &:hover {
        background-color: #e9ecef;
      }
    }

    .thai-char-btn {
      width: 60px;
    }
  }

  // .app-public-browse-body {
  //   #law-result-table {
  //     .p-datatable {
  //       .p-datatable-wrapper {
  //         overflow-x: hidden;
  //       }

  //       p-paginator {
  //         .p-paginator-bottom {
  //           border: none;
  //           justify-content: start;
  //           padding-left: 0;

  //           .p-paginator-current {
  //             padding-left: 0;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

#law-result-table {
  .p-datatable {
    .p-datatable-wrapper {
      overflow-x: hidden;
    }

    p-paginator {
      .p-paginator-bottom {
        border: none;
        justify-content: start;
        padding-left: 0;

        .p-paginator-current {
          padding-left: 0;
        }
      }
    }
  }
}

.app-public-search-panel-law,
.app-public-search-panel-comment,
.app-public-search-panel-other {
  background-color: #f8f9fa;

  p-multiselect {
    .p-multiselect {
      width: 100%;
    }
  }

  p-dropdown {
    .p-dropdown {
      width: 100%;
    }
  }

  hr {
    height: 0.5px !important;
  }
}

.p-scrolltop.p-link {
  background: #5BAF1F;

  &:hover {
    background: #142838;
  }
}

.browse-tag {
  .p-tag {
    // color: #6c757d;
    color: #888888;
    font-weight: 500;
    // background: #dedede;
    // background: #ececec;
    background: #f4f4f4;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
  }
}

.text-content-color {
  color: #888888;
}

.preview-html {
  white-space: pre-wrap;

  .MsoNormal {
    span {
      white-space: initial !important;
    }
  }

  span {
    font-family: var(--font-family) !important;
    font-size: 1rem !important;
  }

  .footnote-detect {
    padding: 0px;
  }

  a {
    padding: 0px;
  }

  p {
    word-break: break-word;
  }

  table {
    // table-layout: fixed;
    width: 100%;
    // max-width: calc(100vw - 63vw);
    word-break: break-word;
    margin-bottom: 10px;

    td {
      border-width: 1px !important;
      border-color: black;
      padding: 5px 3px;
    }
  }

  img {
    max-width: 100%;
  }
}

p-carousel {
  &.public-carousel-slide-menu {
    .p-carousel {
      .p-carousel-content {
        .p-carousel-indicators {
          .p-carousel-indicator {
            button {
              width: 0.5rem !important;
              height: 0.5rem !important;
              border-radius: 50% !important;
              background-color: #CCCCCC;
            }
          }
        }
      }
    }
  }
}
.vendor-page .p-carousel .p-carousel-items-content .p-carousel-item {
  align-self: center;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #5BAF1F !important;
}

.social-icon-section {
  height: 60px;
}

// .hover-increase-icon-size.pi:hover {
//   font-size: 42px;
//   transition: 0.3;
// }

.hover-increase-icon-size {
  &.pi:hover {
    font-size: 42px;
    transition: 0.3;
  }

  &.fab:hover {
    font-size: 42px;
    transition: 0.3;
  }

  &.fas:hover {
    font-size: 42px;
    transition: 0.3;
  }
}

.facebook-share-button {
  .p-button-icon {
    font-size: 1.3rem;
  }
}

/*** Edited style of sortable column ***/
p-table {
  .p-component-overlay {
    background-color: unset !important;
  }

  .p-datatable-loading-icon {
    display: none;
  }

  .p-datatable-thead {
    tr {
      th.p-sortable-column {
        &.p-highlight {
          span {
            color: #2196f3 !important;
          }
        }

        &:hover {

          span,
          p-sorticon i.p-sortable-column-icon {
            color: #2196f3 !important;
          }
        }

        &.text-center {
          div.column-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        // &:not(.text-center) {
        //   div.column-content {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //   }
        // }

        p-sorticon {
          i.p-sortable-column-icon {
            &.pi-sort-alt::before {
              content: "\e903 \A \e902";
            }

            &.pi-sort-amount-up-alt::before {
              content: "\e903";
            }

            &.pi-sort-amount-down::before {
              content: "\e902";
            }
          }
        }
      }
    }
  }

  &.committee {
    .p-datatable .p-datatable-thead>tr>th {
      background-color: #3a7cba;
      color: #fff;
      padding: 0.75em 1em;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}

/** Edited z-index of mat-menu's popup */
.cdk-overlay-container {
  z-index: 2000;

  .mat-menu-panel {
    max-width: 700px;
  }

  .cdk-overlay-connected-position-bounding-box+.cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      .mat-menu-panel {
        // &.topbar-menu {
        background-color: #3a7cba;

        .mat-menu-content {
          button.mat-menu-item {
            color: #fff;
            font-weight: 500;

            &.mat-menu-item-submenu-trigger::after {
              color: #fff;
            }
          }
        }

        // }
      }
    }
  }
}

p-panel {
  &.public-contact-address {
    .p-panel-header {
      font-size: 1.1rem;
      font-weight: 800;
      color: #0d3c61;
    }
  }
}

.text-header-link {
  color: #0d3c61;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-link {
  color: #2196f3;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-indent {
  text-indent: 72pt;
}

.bg-white {
  background-color: #fbfbfb;
}

p-organizationchart {
  .p-organizationchart-table {
    .p-organizationchart-node-content {
      border-width: 2px;

      &.highlight-label {
        background: #3a7cba;
        color: #fff;
        border-color: #3a7cba;

        .p-node-toggler-icon {
          color: #fff;
        }
      }

      &.separate-label {
        border: none;
      }

      &.p-organizationchart-selectable-node {
        font-weight: 600;
        color: #2196f3;
        border-color: #2196f3;
        text-decoration: underline;

        &:not(.p-highlight):hover {
          color: #2196f3;
          // text-decoration: underline;
        }
      }
    }
  }
}

p-scrollpanel {
  .p-scrollpanel {
    &.custombar1 {
      .p-scrollpanel-wrapper {
        border-right: 9px solid lightgray;
      }

      .p-scrollpanel-bar {
        background-color: #0d89ec;
        opacity: 1;
        transition: background-color 0.2s;

        &:hover {
          background-color: #1c548f;
        }
      }
    }
  }
}

.MJXc-TeX-unknown-I {
  font-style: normal !important;
  width: 100% !important;
}

.bullhorn {
  position: fixed !important;
  right: 1rem;
  bottom: 1rem;
  background-color: #ebebeb;
  border: 0;

  &:hover {
    background-color: #cccccc !important;

    &::before {
      border-right: 26px solid #cccccc;

    }
  }
}

.bullhorn:before {
  content: "";
  position: fixed !important;
  right: 10rem;
  border-right: 26px solid #ebebeb;
  border-bottom: 13px solid transparent;

  &:hover {
    border-right: 26px solid #cccccc;
  }
}

.p-button.icon-text-btn {
  .p-button-icon {
    margin-right: 0.5rem;
  }

}

.page-banner {
  width: 100%;
}

.icon-round {
  background-color: #5BAF1F;
  width: 100;
  margin: auto;
  padding: 20px;
  border-radius: 30px;
  color: #FFFFFF;
}

.card-group {
  margin: auto;
  justify-content: center;
}

.text-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  left: 20vw;
  color: #FFFFFF;
  font-size: 3vw;
  font-weight: bold;
}

.banner-image {
  position: relative;
  text-align: center;
}

.p-rating {
  text-align: center;

  .p-rating-icon.pi-star {
    color: #5BAF1F;

    &:hover {
      border-color: red;
    }
  }
}

.public-review {
  .p-rating {
    text-align: start;

    .p-rating-icon {
      font-size: 2rem;

      &:hover {
        color: #5BAF1F !important;
      }
    }
  }
}

.contact-detail {
  max-width: 90%;
  margin: auto;
}


.header {
  font-size: 2.5vw;
  font-weight: bold;
}

@media screen and (max-width: 576px) {

  .header {
    font-size: 16px;
    font-weight: bold;
  }
}

@media screen and (max-width: 576px) {
  .p-dialog {
    max-width: 320px;
  }
  p-dialog.custom-fullscreen-dialog {
    .p-dialog {
      max-width: 95vw !important;
    }
  }
}



/* Pagination */
.p-paginator {
  text-align: right !important;
  display: block !important;
}

@media screen and (max-width: 400px) {
  .p-paginator {
    width: 100%;
    text-align: center !important;

    button {
      min-width: 1.75rem !important;
    }
  }

  .p-dialog {
    max-width: 320px;
  }
}

.p-tooltip {
  max-width: fit-content;
}

.cards-header {
  font-size: 16px;
  display: flex;
  font-weight: bold;
}

.cards-detail {
  font-size: 11px;
  color: #888888;
  display: flex;
}

.cards-content {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 1.5rem;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #5BAF1F;
  background: #5BAF1F;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  border-color: #5BAF1F;
  box-shadow: 0 0 0 0.2rem #abda89;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #5BAF1F;
  background: #5BAF1F;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus{
  outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #5baf1f;
    border-color: #5BAF1F;
}

.p-tag {
  background: #F8F8F8;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.tag-active {
  .p-tag {
    background: #5BAF1F;
    color: #ffffff
  }
}

.image-banner-text {
  position: relative;
  top: 10rem;
  width: 40%;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  margin-left: 2rem;
  height: 0;
}

.image-banner-btn {
  position: relative;
  top: 20rem;
  color: #FFFFFF;
  margin-left: 2rem;
  clip-path: polygon(0 0, 90% 0%, 100% 30%, 100% 90%, 100% 100%, 0 100%, 0% 90%, 0% 30%);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
  position: absolute;
  right: 15px;
}

.set-role .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
  position: absolute;
  right: 15px;
  display: none;
}

// .p-carousel .p-carousel-content .p-carousel-prev{
//   width: 0.5rem !important;
//   height: 0.5rem !important;
// }

// .p-carousel .p-carousel-content .p-carousel-next{
//   width: 0.5rem !important;
//   height: 0.5rem !important;
// }


.p-galleria .p-galleria-thumbnail-container {
  background: #ffffff;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next{
  color: #000000;
}

.p-galleria-thumbnail-item-content {
  margin: 1rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
  background: #5BAF1F;
}

.p-tabmenu {
  .p-tabmenu-nav {
    border: none;

    .p-tabmenuitem {
      &.p-highlight {
        .p-menuitem-link {
          border-color: #6c757d;
          color: #6c757d;
        }
      }

      .p-menuitem-link {
        border: none;
        margin: 0;
      }
    }
  }
}

.p-accordion {
  .p-accordion-tab-active{
      .p-accordion-header {
        .p-accordion-header-link{
          background-color: #5BAF1F !important;
          color: #ffffff;
        }
      }
  }
}