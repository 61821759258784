$mobile-topbar-height: 90px;
$mobile-topbar-total-height: 140px;

@media screen and (max-width: 960px) {
  .layout-wrapper.layout-userbar-active {
    .layout-userbar {
      top: $mobile-topbar-height;
    }

    .layout-content {
      // padding-top: $mobile-topbar-total-height;
    }

    .layout-sidebar {
      top: 0;
      height: 100%;
    }

    .layout-userbar-button {
      opacity: 0;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
    }
  }

  .layout-topbar {
    height: $mobile-topbar-height;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    .menu-button {
      display: block;
    }

    .logo {
      img {
        // width: 150px;
      }
    }

    .app-theme {
      margin-left: 0;
      margin-right: 23px;
    }

    .topbar-menu {
      background-color: var(--surface-a);
      width: 100%;
      height: 40px;
      margin: 0;
      border-top: 1px solid var(--surface-d);

      >li {
        height: 40px;
        line-height: 40px;
        width: 25%;

        >a {
          padding-bottom: 0;
          height: 40px;
          line-height: 38px;
          width: 100%;
          font-size: 12px;
          font-weight: 600;
          min-width: auto;
        }

        &.topbar-submenu>ul {
          top: 40px;
        }
      }
    }
  }

  .layout-sidebar {
    top: 0;
    z-index: 999;
    height: 100%;
    transform: translateX(-100%);

    &.active {
      transform: translateX(0);
    }

    .app-topbar-config {
      display: none;
    }
  }

  .layout-content {
    margin-left: 0;
    // padding-top: $mobile-topbar-height;

    .content-section {
      &.introduction {
        flex-direction: column;

        .app-inputstyleswitch {
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }
    }

    .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .layout-mask {
    background-color: rgba(0, 0, 0, 0.1);

    &.layout-mask-active {
      z-index: 998;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      transition: background-color 0.5s;
    }
  }

  .home {
    .introduction>div {
      width: 100%;
    }

    .features>div {
      width: 100%;
    }

    .whouses>div {
      width: 100%;
    }

    .prosupport>div {
      width: 100%;
    }
  }

  .layout-config {
    .layout-config-button {
      left: auto;
      right: -52px;
    }

    &.layout-config-active {
      width: 100%;
    }
  }

  .blocked-scroll {
    overflow: hidden;
  }
}

@media screen and (max-width: 640px) {
  .layout-wrapper {
    min-width: 480px;
  }

  .layout-wrapper.layout-userbar-active {
    .topbar-menu {
      >li {
        &.topbar-submenu {
          >ul {
            top: $mobile-topbar-total-height;
          }
        }
      }
    }
  }

  .layout-topbar {
    min-width: 480px;

    .topbar-menu {
      >li {
        &.topbar-submenu {
          position: static;

          >ul {
            top: $mobile-topbar-height;
            position: fixed;
            right: auto;
            left: 0;
            width: 100vw;
          }
        }
      }
    }

    .app-topbar-config {
      display: none !important;
    }
  }

  .layout-userbar {
    min-width: 480px;
  }

  .layout-sidebar {
    .app-topbar-config {
      display: flex !important;
    }
  }
}

.container-full {
  background-color: #FFFFFF;
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1170px) {
  .container-lg {
    max-width: 960px;
  }
}


@media (min-width: 768px) {
  .d-ms-none {
    display: none !important;
  }
  .d-ms-contents {
      display: contents !important;
  }
  .mx-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}