$topbar-height: 60px;
$userbar-height: 50px;
$userbar-border-height: 5px;
$topbar-total-height: $topbar-height + $userbar-height;

$topbar-height: 60px;
$top-menubar-height: 50px;
$top-menubar-border-height: 5px;
$topbar-total-height: $topbar-height + $top-menubar-height;

$border-radius: 6px;
$border-style: 2px solid #dee2e6;