$linkColor: #2196f3;
$focusBorderColor: #bbdefb;
// @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@font-face {
  font-family: "CS_PraJad";
  src: url("../../fonts/CS_PraJad/CSPraJad.otf")
    format("truetype");
  font-weight: 500;
  // src: url('webfont.eot'); /* IE9 Compat Modes */
  // src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //      url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
  //      url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
  //      url('../../fonts/TH-Baijam/TH Baijam.ttf')  format('truetype'), /* Safari, Android, iOS */
  //      url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */

}

// @font-face {
//   font-family: "CS_PraJad";
//   src: url("~/fonts/CSPraJad/CSPraJad-bold.otf")
//     format("truetype");
//   font-weight: 700;
//   font-style: normal;
// }

@font-face {
  font-family: "CS_PraJad";
  src: url("../../fonts/CS_PraJad/CSPraJad-bold.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

:root {
  // --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
  //   Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --font-family: CS_PraJad, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  --table-header-color: var(--blue-900);
  --header-color: var(--blue-900);
  --highlight-color: var(--blue-900);
  --text-color: var(--blue-900);
  --primary-color: #1b8de9;
  --placeholder-color: #CCCCCC;
}

@mixin focused() {
  outline: 0 none;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 0.2rem $focusBorderColor;
}

html {
  font-size: 13px;
}

body {
  margin: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-a);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.text-label {
  color: var(--text-color-secondary);
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

pre[class*="language-"] {
  &:before,
  &:after {
    display: none !important;
  }

  code {
    border-left: 10px solid var(--surface-d) !important;
    box-shadow: none !important;
    background: var(--surface-e) !important;
    margin: 1em 0;
    color: var(--text-color);
    font-size: 14px;

    .token {
      &.tag,
      &.keyword {
        color: #2196f3 !important;
      }

      &.attr-name,
      &.attr-string {
        color: #2196f3 !important;
      }

      &.attr-value {
        color: #4caf50 !important;
      }

      &.punctuation {
        color: var(--text-color);
      }

      &.operator,
      &.string {
        background: transparent;
      }
    }
  }
}

.p-toast.p-toast-topright,
.p-toast.p-toast-topleft {
  top: 100px;
}

.action-button {
  font-weight: bold;
  text-align: center;
  color: #ffffff !important;
  background-color: #455c71;
  padding: 10px 24px 9px 24px;
  border-radius: 3px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #708ea5;
    color: #ffffff;
    text-decoration: none !important;
  }
}
