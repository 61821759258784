@import "./variables";

.layout-top-menubar {
  left: 0;
  z-index: 997;
  width: 100%;
  padding: 25px 0;
  max-height: 120px;
  // margin-bottom: 25px;

  background-color: #FFFFFF;
  // padding-bottom: $top-menubar-border-height;

  // &:after {
  //   content: "";
  //   background: -webkit-linear-gradient(left, #FFFFFF 0%, #FFFFFF 100%);
  //   display: block;
  //   height: $top-menubar-border-height;
  //   width: 100%;
  //   position: absolute;
  //   // bottom: 0;
  // }

  &.layout-banner-top-menubar:after {
    background: none;
  }

  // .top-menubar-bottom-bar {
  //   background: -webkit-linear-gradient(left, #2f75b6 0%, #b4c7e7 100%);
  //   display: block;
  //   height: $top-menubar-border-height;
  //   width: 100%;
  //   // position: absolute;
  // }

  

  .layout-top-menubar-container {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    color: #ffffff;
    font-weight: bold;
    // font-size: 20px;
    // padding: 0 35px;

    .logo {
      img {
        margin-left: 2rem;
        margin-right: 2rem;
        max-width: 150px;
        position: relative;
        top: -1vw;
      }

      &:focus {
        @include focused();
      }
    }

    .top-menu {
      flex: auto;
      justify-content: end;

      .topbar-menu {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        flex-wrap: nowrap;

        &:hover {
          height: 45px;
          color: #5BAF1F;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: nowrap;
        }
      }

      .topbar-menu-active {
        height: 45px;
        color: #5BAF1F !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .topbar-text {
        color: #000000;
        white-space: nowrap !important;
        cursor: pointer;
      }
    }

    .layout-top-menubar-details {
      display: flex;
      align-items: center;
      z-index: 1;

      .rate {
        color: #f2b837;
        font-size: 25px;
        font-weight: bold;
        margin: 0 0.25rem;
      }

      .helper-text {
        background-color: #f2b837;
        color: #212121;
        padding: 0 0.2rem;
        margin-right: 0.3rem;
      }
    }

    img.layouts-top-menubar-mockup-image {
      height: $top-menubar-height;
    }

    .layout-top-menubar-button {
      border: 1px solid #ffffff;
      color: #ffffff;
      font-size: 15px;
      padding: 0.3em 0.3em 0.5em 1em;
      font-weight: bold;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      transition: background-color 0.15s;
      margin-left: 2em;
      position: relative;
      z-index: 1;

      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }

    .user-role button {
      font-size: 0.875rem;
      padding: 0.4375rem 0.875rem;
    }

    .user-role-menu {
      font-size: 0.875rem;
      padding: 0.4375rem 0.875rem;
      display: inline-flex;
      border: none;
      height: 32px;
      min-width: 115px;
    }
  }

  .layout-top-menubar-close {
    cursor: pointer;
    color: #fff;
    position: absolute;
    z-index: 2;
    right: 28px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
  }
}

p-dialog.custom-fullscreen-dialog {
  .p-dialog {
      position: inherit;
      left: 0;
      top: 0;
      max-height: 100%;

    .p-dialog-header {
      display: flex;
      padding-right: 2.75rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    .p-dialog-footer {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.layout-dynamic-sidebar {
  left: 0;
  height: 100%;
  background-color: var(--surface-a);
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  display: block;
  flex-direction: column;

  .layout-menu {
    padding: 0 1rem;
    overflow-y: auto;
    flex-grow: 1;

    .menu-items {
      padding: 0 0 1rem 0;
      display: flex;
      flex-direction: column;

      a,
      .top-menu-group {
        color: var(--text-color);
        display: block;
        padding: 0.5rem;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background-color: var(--surface-c);
          color: #5BAF1F !important;
        }

        &:focus {
          z-index: 1;
          outline: 0 none;
          transition: box-shadow 0.2s;
          box-shadow: 0 0 0 0.2em $focusBorderColor;
        }

        
      }
      .topbar-menu-active {
        color: #5BAF1F !important;
      }

    }
  }

  .app-topbar-config {
    display: none;
  }

  .config-menu{
    position: initial;
    text-align: end;
    margin-bottom: 2rem;
  }
}


.country-item {
  display: inherit !important;
  align-items: end;
  text-align: center;

  img.flag {
      width: 23px !important;
      margin-right: 0rem !important;
  }
}

.flag {
  .p-dropdown{
      border: 0;
  }
}

.badge-topbar{
  .p-button {
      .p-badge{
          position: absolute;
          top: 0;
          right: -1px;
      }
  }
}

.p-button.defalut-btn{
  background-color: #27448d !important;
  border-color: #27448d !important;
  &:hover { 
      background-color: #000000 !important;
      border-color: #000000 !important;
  }
}

.p-button.search-btn{
  background-color: #5BAF1F !important;
  border-color: #5BAF1F !important;
  &:hover { 
      background-color: #386b13 !important;
      border-color: #386b13 !important;
  }
}

.p-button.setting-btn {
  width: auto;
}



@media screen and (max-width: 1190px) {
  .top-menu {
      position: relative;
      margin-top: 10px;
      height: 150px;
      width: 100%;
      justify-content: start !important;
      padding-left: 2rem;
  }

  .config-menu {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 992px) {

  .logo {
    position: inherit !important;
  }

  .layout-top-menubar .layout-top-menubar-container {
    justify-content: center !important;
  }
}

.logo {
  position: absolute;
}

.menu-button {
  cursor: pointer;
  color: #000000 
}